//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Large.vue";
import Wrapper from "./Wrapper";
export default {
  mixins: [ModalMixin],
  components: {
    Modal,
    Wrapper
  }
};