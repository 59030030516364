/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalLimitReachedKwd.vue?vue&type=template&id=4e965edf&scoped=true&"
import script from "./ModalLimitReachedKwd.vue?vue&type=script&lang=js&"
export * from "./ModalLimitReachedKwd.vue?vue&type=script&lang=js&"
import style0 from "./ModalLimitReachedKwd.vue?vue&type=style&index=0&id=4e965edf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e965edf",
  null
  
)

export default component.exports